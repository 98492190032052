document.addEventListener("turbolinks:load", function () {
  $('.js-contest-share-button').on('click', function () {
    $(this).next().slideToggle(300);
  });

  $('.js-contest-share-close-button').on('click', function () {
    $('.js-contest-share-modal').slideToggle(300);
  });
});

$(function(){
  $(document).on('click', '.js-contest-copy-url-button', function () {
    const url = $(this).data('url');
    const area = document.createElement('textarea');
    area.textContent = url;
    document.body.appendChild(area);
    area.select();
    document.execCommand('copy');
    document.body.removeChild(area);
    $(this).html('コピー<br>しました');
  });
});
