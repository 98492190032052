let timerId = null;
$(function(){
  $(document).on('click', '.js-copy-url-button', function () {
    const url = $(this).data('url');
    const area = document.createElement('textarea');
    area.textContent = url;
    document.body.appendChild(area);
    area.select();
    document.execCommand('copy');
    document.body.removeChild(area);
    if (timerId) {
      clearTimeout(timerId);
      timerId = null;
    }
    $('.message-copied').show();
    timerId = setTimeout(function() {
      $('.message-copied').hide();
    }, 1500);
  });
});
