const socialAccountDataLocalStorageKey = 'social_account_data_key';

window.storeSocialAccountData = (value) => {
  localStorage.setItem(socialAccountDataLocalStorageKey, JSON.stringify(value));
};

window.getSocialAccountData = () => {
  return JSON.parse(localStorage.getItem(socialAccountDataLocalStorageKey) || null);
};

import './facebook_login';
import './line_login';
import './twitter_login';

document.addEventListener("turbolinks:load", function () {
  if ($('.js-user-registration-hidden-fields').length == 0) {
    storeSocialAccountData(null);
    return;
  }
  $('.js-user-registration-hidden-fields').each(function () {
    const data = getSocialAccountData();
    if (!data) {
      return;
    }
    Object.keys(data).forEach(function (key1) {
      Object.keys(data[key1]).forEach(function (key2) {
        const val = data[key1][key2];
        $('.js-user-registration-hidden-fields').append(
          $('<input type="hidden" name="user[' + key1 + '][' + key2 + ']" value="' + val + '" />')
        );
        if (key2 === 'email') {
          if ($('input[name="user[email]"]').val().length === 0) {
            $('input[name="user[email]"]').val(val);
          }
        }
      });
    });
  });
});
