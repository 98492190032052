document.addEventListener("turbolinks:load", function () {
  const localStorageKey = 'last_accessed_at';
  const newLocalStorageValue = new Date().getTime();
  const oldLocalStorageValue = Number(localStorage.getItem(localStorageKey));

  if (!oldLocalStorageValue || (newLocalStorageValue - oldLocalStorageValue) / 1000 / 60 > 30) {
    $.ajax({
      url: '/consumer/current_user_session',
      method: 'PATCH',
      dataType: 'json'
    });
  }
  localStorage.setItem(localStorageKey, newLocalStorageValue);
});
