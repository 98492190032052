document.addEventListener("turbolinks:load", function () {
  window.blockingEpisodeListRequest = false;
});


document.addEventListener("turbolinks:request-start", function () {
  window.blockingEpisodeListRequest = true;
});

document.addEventListener("turbolinks:render", function () {
  $('.episode-list').each(function () {
    if (window.blockingEpisodeListRequest) {
      return;
    }
    const data = $(this).data();
    if (data.story_id) {
      $.ajax({
        url: `/stories/${data.story_id}/${data.type}.js`,
        data: {
          limit: data.limit,
          order: data.order
        }
      });
    }
  });
});
