import jquery from 'jquery';
window.$ = jquery;
import Turbolinks from 'turbolinks';
window.Turbolinks = Turbolinks;

import 'lazyload/lazyload.js';

document.addEventListener("turbolinks:load", function () {
  let images = document.querySelectorAll('img.lazyload');
  lazyload(images, {
    rootMargin: '500px'
  });
});

document.addEventListener("turbolinks:render", function () {
  let images = document.querySelectorAll('img.lazyload');
  images.forEach(function(image) {
    image.src = image.dataset.src;
    image.classList.remove('lazyload');
  });
});

// import 'jquery.smooth-scroll';
import 'slick-carousel';

import './shared/traffic_source';
import './shared/session';
import './shared/accordion';
import './shared/analytics';
import './shared/carousel';
import './shared/episode_viewer';
import './shared/genres_slider';
import './shared/goto_top';
import './shared/header';
import './shared/modal';
import './shared/modal_menu';
import './shared/copy_url';
import './shared/disable_right_click';
import './shared/loading';

import './contacts/new';
import './home/index';
import './notifications/index';
import './settings/index';
import './social_logins/index';
import './stories/index';
import './stories/show';
import './episodes/index';
import './episode_comments/index';
import './episode_comments/new';
import './user/new';
import './user/edit';
import './contests/show';
import './contest_entry_stories/index';
