window.sendDefaultCustomEvent = function (args) {
  gtag("event", "default_custom_event", {
    eventName: `${args.actionName}_${args.resourceType}`,
    resourceType: args.resourceType,
    resourceId: args.resourceId,
    resourceName: args.resourceName,
    resourceValue: args.resourceValue,
    actionName: args.actionName,
    source: args.source,
    amount: args.amount,
  });
}

window.sendStandardEvent = function (args) {
  gtag("event", "standard_event", {
    eventName: args.eventName,
    itemCategory: args.itemCategory,
    itemId: args.itemId
  });
}

window.setUserId = function (userId) {
  gtag("set", "user_id", userId);
}

window.doneDefeaultCustomEvents = {};

document.addEventListener("turbolinks:load", function () {
  if (!window['defeaultCustomEvents']) {
    return;
  }
  defeaultCustomEvents.forEach(function (defeaultCustomEvent) {
    const event = {
      resourceType: defeaultCustomEvent.resource_type,
      resourceId: defeaultCustomEvent.resource_id,
      actionName: defeaultCustomEvent.action_name,
    };
    const localStorageKey = 'done_defeault_custom_events';
    const localStorageValue = JSON.parse(localStorage.getItem(localStorageKey) || '[]');
    const key = JSON.stringify(event);
    if (localStorageValue.includes(key)) {
      return;
    }
    localStorageValue.push(key);
    localStorage.setItem(localStorageKey, JSON.stringify(localStorageValue));
    sendDefaultCustomEvent(event);
  });
});
