import Turbolinks from 'turbolinks';

const setFillHeight = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  document.documentElement.scrollTop = 0;
}

document.addEventListener("turbolinks:load", function () {
  $('iframe.js-viewer-frame').each((i, elem) => {
    const iframe = $(elem);
    $('.js-share-modal-open').on('click', () => {
      $('.js-share-overlay').show();
      $('.js-share-modal').show();
    });
    $('.js-share-modal-close').on('click', () => {
      $('.js-share-overlay').hide();
      $('.js-share-modal').hide();
    });
  });

  const appOpenButton = $('.js-app-open-button')
  appOpenButton.on('click', (elem) => {
    window.open(appOpenButton.data('url'), '_blank')
  });

  $('#episode-viewer-page').each(() => {
    window.addEventListener('resize', setFillHeight);
    setFillHeight();
  });
});

document.addEventListener("turbolinks:before-cache", function () {
  window.removeEventListener('resize', setFillHeight);
});

var currentUserId;

window.addEventListener('message', (event) => {
  const iframe = $('iframe.js-viewer-frame');
  if (iframe.length === 0) {
    return;
  }
  if (new URL(event.origin).origin !== new URL(iframe.attr('src')).origin) {
    return;
  }
  if (event.data === 'startViewer') {} else if (event.data === 'finishViewer') {
    const returnTo = new URLSearchParams(location.search).get('return_to');
    if (returnTo) {
      location.href = returnTo;
    } else {
      const episodeId = iframe.attr('src').match(/episode_id=(\d+)/)[1]
      iframe[0].contentWindow.location.replace(`/episodes/${episodeId}/comments`);
    }
  } else if (event.data === 'closeViewer') {
    if (document.referrer == "" || !document.referrer.match(/\/stories\/\d+/)) {
      const storyId = iframe.data('story_id');
      Turbolinks.visit(`/stories/${storyId}`, {action: "replace"});
    } else {
      history.back();
    }
  } else if (event.data === 'getAccessToken') {
    $.ajax({
      url: '/consumer/users/access_token',
      type: 'POST',
      success: (data, status, xhr) => {
        currentUserId = data.id;
        event.source.postMessage('sendAccessToken,' + data.token, event.origin);
      }
    })
  } else if (event.data === 'getWindowWidth') {
    event.source.postMessage('sendWindowWidth,' + String(window.innerWidth), event.origin);
  } else if (event.data.startsWith('createEpisodeReadingStartTime/')) {
    const values = event.data.split('/');
    sendDefaultCustomEvent({
      resourceType: 'episode_reading_start_time',
      resourceId: Number(values[1]),
      actionName: 'create'
    });
    switch (Number(values[2])) {
      case 1:
        sendDefaultCustomEvent({
          resourceType: 'episode_reading_start_time_1',
          resourceId: currentUserId,
          actionName: 'create'
        });
        break;
      case 2:
        sendDefaultCustomEvent({
          resourceType: 'episode_reading_start_time_2',
          resourceId: currentUserId,
          actionName: 'create'
        });
        break;
    }
    const storyId = iframe.data('story_id');
    $.ajax({
      url: `/consumer/user_traffic_sources?user_traffic_source[name]=story${storyId}`,
      success: (data, status, xhr) => {
        if (xhr.getResponseHeader('total') > 0) {
          sendDefaultCustomEvent({
            resourceType: 'episode_reading_start_via_ad',
            resourceId: currentUserId,
            actionName: 'create'
          });
        }
      }
    });
  } else if (event.data.startsWith('createEpisodeReadingFinishTime/')) {
    const values = event.data.split('/');
    sendDefaultCustomEvent({
      resourceType: 'episode_reading_finish_time',
      resourceId: Number(values[1]),
      actionName: 'create'
    });
    switch (Number(values[2])) {
      case 1:
        sendDefaultCustomEvent({
          resourceType: 'episode_reading_finish_time_1',
          resourceId: currentUserId,
          actionName: 'create'
        });
        break;
      case 2:
        sendDefaultCustomEvent({
          resourceType: 'episode_reading_finish_time_2',
          resourceId: currentUserId,
          actionName: 'create'
        });
        break;
    }
    const storyId = iframe.data('story_id');
    $.ajax({
      url: `/consumer/user_traffic_sources?user_traffic_source[name]=story${storyId}`,
      success: (data, status, xhr) => {
        if (xhr.getResponseHeader('total') > 0) {
          sendDefaultCustomEvent({
            resourceType: 'episode_reading_finish_via_ad',
            resourceId: currentUserId,
            actionName: 'create'
          });
        }
      }
    });
  } else if (event.data.startsWith('createSceneTapsBatch/')) {
    sendDefaultCustomEvent({
      resourceType: 'scene_taps_batch',
      resourceId: event.data.split('/')[1],
      resourceValue: Number(event.data.split('/')[2]),
      actionName: 'create'
    });
  } else if (event.data.startsWith('updateAutoPlaySpeed/')) {
    const values = event.data.split('/');
    sendDefaultCustomEvent({
      resourceType: 'auto_play_speed',
      resourceId: Number(values[1]),
      resourceValue: Number(values[2]),
      actionName: 'update'
    });
  } else if (event.data.startsWith('updateEnabledSound/')) {
    const values = event.data.split('/');
    sendDefaultCustomEvent({
      resourceType: 'enabled_sound',
      resourceId: Number(values[1]),
      resourceValue: values[2],
      actionName: 'update'
    });
  }
}, false);
