document.addEventListener("turbolinks:load", function() {
  $('.js-visible-loading-spinner').on('click', function () {
    const overlay = document.createElement('div');
    overlay.className = "overlay js-modal-overlay";
    overlay.style.display = "block";
    document.body.appendChild(overlay);

    const loading = document.createElement('div');
    loading.className = "loading-spinner js-loading-spinner";
    loading.style.position = 'fixed';
    loading.style.top = 'calc(50% - 16px)';
    loading.style.left = 'calc(50% - 16px)';
    loading.style.zIndex = 110000;
    document.body.appendChild(loading);
  });
});
