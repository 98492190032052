document.addEventListener("turbolinks:load", function () {
  var state_menu = false;
  var state_search = false;
  var scrollpos;

  $('.header-menu,.nav-menu-cancel').on('click', function () {

    $('body').removeClass('fixed').css({
      'top': 0
    });
    $('.modal-search, .header-search').removeClass('open');
    window.scrollTo(0, scrollpos);
    state_search = false;

    if (state_menu == false) {
      scrollpos = $(window).scrollTop();
      $('body').addClass('fixed').css({
        'top': -scrollpos
      });
      $('.js-free-ticket-count').each(function () {
        $.ajax({
          url: '/current_user_status',
          success: (data) => {
            $('.js-free-ticket-count').text(data.free_ticket_count);
          },
          error: (e) => {
          }
        });
      });
      $('.modal-menu, .header-menu').addClass('open');
      if ($('.smartbanner').is(":visible")) {
        $('.modal-menu').css({
          top: $('.smartbanner').height()
        });
      }
      state_menu = true;
    } else {
      $('body').removeClass('fixed').css({
        'top': 0
      });
      $('.modal-menu, .header-menu').removeClass('open');
      window.scrollTo(0, scrollpos);
      state_menu = false;
    }
  });

  $('.header-search,.nav-search-cancel').on('click', function () {
    $('body').removeClass('fixed').css({
      'top': 0
    });
    $('.modal-menu, .header-menu').removeClass('open');
    window.scrollTo(0, scrollpos);
    state_menu = false;

    if (state_search == false) {
      scrollpos = $(window).scrollTop();
      $('body').addClass('fixed').css({
        'top': -scrollpos
      });
      $('.modal-search, .header-search').addClass('open');
      if ($('.smartbanner').is(":visible")) {
        $('.modal-search').css({
          top: $('.smartbanner').height()
        });
      }
      state_search = true;
    } else {
      $('body').removeClass('fixed').css({
        'top': 0
      });
      $('.modal-search, .header-search').removeClass('open');
      window.scrollTo(0, scrollpos);
      state_search = false;
    }
  });

  const inputRemoveButton = $('.nav-search-input .js-search-input-remove');
  inputRemoveButton.toggle(!!$('.nav-search-input input[name="query"]').val());
  $('.nav-search-input input[name="query"]').on('input', (elem) => {
    inputRemoveButton.toggle(!!elem.target.value);
  });
  $('.nav-search-input .js-search-input-remove').on('click', () => {
    $('.nav-search-input input[name="query"]').val('');
    inputRemoveButton.toggle(false);
  });

  const searchQueryHistoriesKey = "search_query_histories";
  const getSearchQueryHistories = function () {
    return JSON.parse(localStorage.getItem(searchQueryHistoriesKey) || '[]');
  }
  const setSearchQueryHistories = function (value) {
    localStorage.setItem(searchQueryHistoriesKey, JSON.stringify(value));
  }

  const menuSearchHistoryTemplate = document.querySelector('#menu-search-history-template');
  const menuSearchHistoriesElem = document.querySelector('.js-menu-search-histories');
  if (menuSearchHistoryTemplate && menuSearchHistoriesElem) {
    let searchQueryHistories = getSearchQueryHistories();
    if (searchQueryHistories.length > 0) {
      const container = document.querySelector('.menu-search-histories')
      if (container) {
        container.style.display = 'block';
      }
      searchQueryHistories.forEach((val) => {
        const template = document.importNode(menuSearchHistoryTemplate.content, true);
        template.querySelector('.js-menu-search-history-link').href = "/search?query=" + val;
        template.querySelector('.js-menu-search-history-query').innerText = val;
        const elem = template.querySelector('li');
        template.querySelector('.js-menu-search-history-delete-button').onclick = function () {
          let searchQueryHistories = getSearchQueryHistories();
          searchQueryHistories = searchQueryHistories.filter((i) => i != val);
          setSearchQueryHistories(searchQueryHistories);
          elem.remove();
          if (container && searchQueryHistories.length == 0) {
            container.style.display = 'none';
          }
        };
        menuSearchHistoriesElem.appendChild(template);
      });
    }
  }

  $('.nav-search-input form').on('submit', function(event) {
    const queryInput = event.target.querySelector('input[name=query]');
    if (!queryInput.value) {
      event.preventDefault();
      return;
    }
    let searchQueryHistories = getSearchQueryHistories();
    searchQueryHistories = searchQueryHistories.filter((i) => i != queryInput.value);
    searchQueryHistories.unshift(queryInput.value);
    searchQueryHistories = searchQueryHistories.slice(0, 10);
    setSearchQueryHistories(searchQueryHistories);
  });
});
