import { isSafari, setupTwitter, connectTwitter } from '../shared/twitter';

const updateEpisodeCommentLength = (elem) => {
  const commentLength = elem.val().length;
  $('.js-submit').prop('disabled', commentLength <= 0 || commentLength > 200);
  $('.js-episode-comment-length').text(commentLength);
};

document.addEventListener("turbolinks:load", function () {
  $('#episode-comment-form-page').each(() => {
    $(document).on('input', 'textarea[name="episode_comment[comment]"]', function () {
      updateEpisodeCommentLength($(this));
    });
    updateEpisodeCommentLength($('textarea[name="episode_comment[comment]"]'));
  });

  const withTwitterPostElem = document.getElementById('episode_comment_with_twitter_post');

  if (withTwitterPostElem) {
    var hasAccessToken = false;
    const getStoredAccessToken = (callback = null) => {
      $.ajax({
        url: '/consumer/social_accounts/twitter_accounts/access_token',
        success: (data) => {
          if (data) {
            hasAccessToken = true;
          }
          if (callback) {
            callback(data);
          }
        }
      });
    };
    const getStoredAccessTokenUnlessSafari = (callback) => {
      if (isSafari()) {
        callback();
      } else {
        getStoredAccessToken(callback);
      }
    };
    if (isSafari()) {
      setupTwitter();
    }
    getStoredAccessToken((data) => {
      withTwitterPostElem.checked = data ? true : false;
    });
    withTwitterPostElem.addEventListener('change', function () {
      getStoredAccessTokenUnlessSafari(() => {
        if (this.checked && !hasAccessToken) {
          withTwitterPostElem.checked = false;
          connectTwitter((row) => {
            const data = {
              twitter_account: {
                access_token: row.access_token,
                access_token_secret: row.access_token_secret,
                user_id: row.user_id,
                screen_name: row.screen_name
              }
            };
            $.ajax({
              url: '/consumer/social_accounts/twitter_accounts/registration',
              method: 'post',
              data: data,
              success: () => {
                withTwitterPostElem.checked = true;
              },
              error: (e) => {
                withTwitterPostElem.checked = false;
                alert(e.responseJSON.oauth_provider.join('/'));
              }
            });
          });
        } else {
          if (isSafari()) {
            setupTwitter();
          }
        }
      });
    });
  }
});
