import Turbolinks from 'turbolinks';

document.addEventListener("turbolinks:load", function () {
  $('#story-page').each(function () {
    if (!location.pathname) {
      return;
    }
    const matched = location.pathname.match(/^\/stories\/(\d+)$/);
    if (!matched) {
      return;
    }
    const itemId = Number(matched[1])
    if (!itemId) {
      return;
    }
    $.ajax({
      url: '/story_page_access_logs',
      method: 'POST',
      data: {
        story_id: itemId
      },
      dataType: 'json'
    });
    sendStandardEvent({
      eventName: 'view_item',
      itemCategory: 'story_detail',
      itemId: itemId
    });
  });
  window.blockingEpisodePrimaryButtonRequest = false;
});


document.addEventListener("turbolinks:request-start", function () {
  window.blockingEpisodePrimaryButtonRequest = true;
});

document.addEventListener("turbolinks:render", function () {
  $('.js-episode-primary-button:first').each(function () {
    if (window.blockingEpisodePrimaryButtonRequest) {
      return;
    }
    const data = $(this).data();
    if (data.story_id) {
      $.ajax({
        url: `/stories/${data.story_id}/episode_primary_button.js`
      });
    }
  });
});
