document.addEventListener("turbolinks:load", function () {
  $('#new-contact').each(function () {
    const consumerContactCategoryIdElem = $('select[name="consumer_contact[consumer_contact_category_id]"]');
    const emailElem = $('input[name="consumer_contact[email]"]');
    const contentElem = $('textarea[name="consumer_contact[content]"]');
    const submitButton = $('.form-submit input[type="submit"]');
    const validateForm = () => {
      return !!consumerContactCategoryIdElem.val() && emailElem.val().length > 0 && contentElem.val().length > 0;
    };
    const toggleSubmit = () => {
      submitButton.prop('disabled', !validateForm());
    }
    consumerContactCategoryIdElem.on('change', function () {
      toggleSubmit();
    });
    emailElem.on('input', function () {
      toggleSubmit();
    });
    contentElem.on('input', function () {
      toggleSubmit();
    });
  });
});
