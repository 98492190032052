document.addEventListener("turbolinks:load", function() {
  $('.nav-loginmenu').on('click', function () {
    $('.nav-login').slideToggle(300);
    $(this).toggleClass('open', 300);
  });

  $('.icon-search').on('click', function () {
    $('input[name="query"]').toggleClass('open');
  });
  $('header.header .nav-global .header-books').on('click', function () {
    Turbolinks.visit('/bookshelf/browsed_stories');
  });

  var position = 0;
  var header = $('.header');
  $(window).on('scroll', function () {
    var d = $(this).scrollTop() - position;
    if (d * d >= 2) {
      if ($(this).scrollTop() > position) {
        header.removeClass('show');
      } else {
        header.addClass('show');
      }
    }
    position = $(this).scrollTop();
  });
});
