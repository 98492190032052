function toggleBottomSubmit() {
  var height = $(document).height(),
      position = window.innerHeight + $(window).scrollTop(),
      submit = $('.js-submit').offset().top,
      bottomSubmit = $('.js-bottom-submit');
  if (height - position  < submit) {
    bottomSubmit.fadeOut();
  } else {
    bottomSubmit.fadeIn();
  }
};

document.addEventListener("turbolinks:load", function () {
  $('#profile').each(function () {
    $('.form-file-mask,.preview-area,.js-profile-image-icon').on('click', function () {
      $('#profimg').trigger('click');
      return false;
    });
    $('#profimg').on('change', function (e) {
      var reader = new FileReader();
      reader.onload = function (e) {
        $('.upload-preview').attr('src', e.target.result);
        $('.preview-area').show();
        $('#clear').show();
        $('.js-clear-img').remove();
        setImageSize(e.target.result);
      }
      reader.readAsDataURL(e.target.files[0]);
    });
    $('#clear').on('click', function () {
      $('.upload-preview').attr('src', '');
      $('.preview-area').hide();
      $('#clear').hide();
      $('input[name="consumer_user[consumer_user_profile_attributes][image]"]').val('');
      $('#clear').after('<input class="js-clear-img" type="hidden" name="_delete_image" value="true" />');
    });

    const submitButton = $('.js-submit');
    const userNameLengthElem = $('.js-user-name-length');
    const profileDescriptionLengthElem = $('.js-user-profile-description-length');
    const userNameCheckedElem = $('.js-user-name-checked');
    const telephoneCheckedElem = $('.js-telephone-checked');
    const profileDescriptionCheckedElem = $('.js-user-profile-description-checked');
    const genderCheckedElem = $('.js-gender-checked');
    const birthdayCheckedElem = $('.js-birthday-checked');
    const prefCheckedElem = $('.js-pref-checked');
    const genreCheckedElem = $('.js-genre-checked');

    var shownBirthdayLockModal = false;

    const userNameElem = $('input[name="consumer_user[user_name]"]');
    const telephoneElem = $('input[name="consumer_user[telephone]"]');
    const profileDescriptionElem = $('textarea[name="consumer_user[consumer_user_profile_attributes][description]"]');

    const updateSubmitButton = () => {
      const userNameLength = userNameElem.val().length;
      const telephoneLength = telephoneElem.length == 0 ? 1 : telephoneElem.val().length;
      const profileDescriptionLength = profileDescriptionElem.val().length;
      submitButton.prop('disabled', userNameLength <= 0 || userNameLength > 20 || profileDescriptionLength > 150 || telephoneLength <= 0);
    };

    const updateUserName = (elem) => {
      const userNameLength = $(elem).val().length;
      $(elem).toggleClass('bg-unentered', userNameLength == 0);
      userNameCheckedElem.toggle(userNameLength > 0);
      submitButton.prop('disabled', userNameLength <= 0 || userNameLength > 20);
      updateSubmitButton();
      userNameLengthElem.text(userNameLength);
    };
    userNameElem.on('input', function () {
      updateUserName(userNameElem);
    });
    updateUserName(userNameElem);

    const updateTelephone = (elem) => {
      const telephoneLength = elem.length == 0 ? 1 : $(elem).val().length;
      $(elem).toggleClass('bg-unentered', telephoneLength == 0);
      telephoneCheckedElem.toggle(telephoneLength > 0);
      submitButton.prop('disabled', telephoneLength <= 0);
      updateSubmitButton();
    };
    telephoneElem.on('input', function () {
      updateTelephone(telephoneElem);
    });
    updateTelephone(telephoneElem);

    const updateProfileDescription = (elem) => {
      const profileDescriptionLength = $(elem).val().length;
      profileDescriptionCheckedElem.toggle(profileDescriptionLength > 0);
      updateSubmitButton();
      profileDescriptionLengthElem.text(profileDescriptionLength);
    };
    profileDescriptionElem.on('input', function () {
      updateProfileDescription(profileDescriptionElem);
    });
    updateProfileDescription(profileDescriptionElem);

    const genderElem = $('select[name="consumer_user[consumer_user_profile_attributes][gender_id]"]');
    const updateGender = (elem) => {
      genderCheckedElem.toggle(!!elem.val());
    };
    genderElem.on('change', function () {
      updateGender(genderElem);
    });
    updateGender(genderElem);

    const birthdayYearElem = $('select[name="consumer_user[consumer_user_profile_attributes][birthday(1i)]"]')
    const birthdayMonthElem = $('select[name="consumer_user[consumer_user_profile_attributes][birthday(2i)]"]')
    const birthdayDayElem = $('select[name="consumer_user[consumer_user_profile_attributes][birthday(3i)]"]')
    const updateBirthday = (yearElem, monthElem, dayElem) => {
      const val = !!yearElem.val() && !!monthElem.val() && !!dayElem.val();
      birthdayCheckedElem.toggle(val);
      if (shownBirthdayLockModal) {
        return;
      }
      if (birthdayYearElem.prop('disabled')) {
        return;
      }
      if (val) {
        shownBirthdayLockModal = true;
        $('.js-modal-overlay-birthday').show();
        $('.js-modal-dialog-birthday').show();
        const closeModal = () => {
          $('.js-modal-overlay-birthday').hide();
          $('.js-modal-dialog-birthday').hide();
        };
        $('.js-modal-close-birthday').on('click', function () {
          closeModal();
        });
        $('.js-modal-overlay-birthday').on('click', function () {
          closeModal();
        });
      }
    };
    birthdayYearElem.on('change', function () {
      updateBirthday(birthdayYearElem, birthdayMonthElem, birthdayDayElem);
    });
    birthdayMonthElem.on('change', function () {
      updateBirthday(birthdayYearElem, birthdayMonthElem, birthdayDayElem);
    });
    birthdayDayElem.on('change', function () {
      updateBirthday(birthdayYearElem, birthdayMonthElem, birthdayDayElem);
    });
    updateBirthday(birthdayYearElem, birthdayMonthElem, birthdayDayElem);

    const prefElem = $('select[name="consumer_user[consumer_user_profile_attributes][pref_id]"]');
    const updatePref = (elem) => {
      prefCheckedElem.toggle(!!elem.val());
    };
    prefElem.on('change', function () {
      updatePref(prefElem);
    });
    updatePref(prefElem);

    const genreElems = $('input[name="consumer_user[favorited_genre_ids][]"]');
    const updateGenre = (elems) => {
      genreCheckedElem.toggle(elems.filter(":checked").length > 0);
    };
    updateGenre(genreElems);

    $('.js-modal-open-genre').on('click', function () {
      $('input[name="genre_ids"]:checked').prop('checked', false);
      $('input[name="consumer_user[favorited_genre_ids][]"]:checked').each(function () {
        const item = $(this);
        const id = Number(item.val());
        $('input[name="genre_ids"][value="' + id + '"]').prop('checked', true);
      });
      $('.js-modal-overlay-genre').show();
      $('.js-modal-dialog-genre').show();
    });
    const closeModal = () => {
      $('.js-modal-overlay-genre').hide();
      $('.js-modal-dialog-genre').hide();
    };
    $('.js-modal-close-genre').on('click', function () {
      closeModal();
    });
    $('.js-modal-overlay-genre').on('click', function () {
      closeModal();
    });
    $('.js-modal-submit-genre').on('click', function () {
      $('input[name="consumer_user[favorited_genre_ids][]"]').prop('checked', false);
      const genres = [];
      $('input[name="genre_ids"]:checked').each(function () {
        const item = $(this);
        const id = Number(item.val());
        genres.push(item.next().text());
        $('input[name="consumer_user[favorited_genre_ids][]"][value="' + id + '"]').prop('checked', true);
      });
      $('.js-modal-open-genre').text(genres.length > 0 ? genres.join(',') : 'ジャンルを選択');
      updateGenre(genreElems);
      closeModal();
    });
    $(window).on('load scroll', toggleBottomSubmit);
  });
});

document.addEventListener("turbolinks:before-cache", function () {
  $(window).off('load scroll', toggleBottomSubmit);
});
