document.addEventListener("turbolinks:load", function () {
  $('.js-user-registration-form').each(function () {
    const submitButtonElem = $('.form-submit .btn-signin');
    const emailInputElem = $('input[name="user[email]"]');
    const passwordInputElem = $('input[name="user[password]"]');
    const userNameInputElem = $('input[name="user[consumer_user_attributes][user_name]"]');
    const userNameCurrentLengthElem = $('.form-username .show-count');
    const updateSubmitButton = () => {
      const disabled =
        !(emailInputElem.val().length > 0 &&
          passwordInputElem.val().length > 0 &&
          userNameInputElem.val().length > 0 &&
          userNameInputElem.val().length <= 20);
      submitButtonElem.prop('disabled', disabled);
    };
    const updateUserNameCurrentLength = () => {
      userNameCurrentLengthElem.text(userNameInputElem.val().length);
    };
    const updateUserName = () => {
      updateUserNameCurrentLength();
      updateSubmitButton();
    };
    emailInputElem.on('input', updateSubmitButton);
    passwordInputElem.on('input', updateSubmitButton);
    userNameInputElem.on('input', updateUserName);
    updateUserName();
  });

  $('.js-user-session-form').each(function () {
    const submitButtonElem = $('.form-submit .btn-signin');
    const emailInputElem = $('input[name="user[email]"]');
    const passwordInputElem = $('input[name="user[password]"]');
    const updateSubmitButton = () => {
      const disabled =
        !(emailInputElem.val().length > 0 &&
          passwordInputElem.val().length > 0);
      submitButtonElem.prop('disabled', disabled);
    };
    emailInputElem.on('input', updateSubmitButton);
    passwordInputElem.on('input', updateSubmitButton);
    updateSubmitButton();
  });
});
