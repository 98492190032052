document.addEventListener("turbolinks:load", function () {
  $('.mypage-contents').each(function () {
    $('.js-modal-open').on('click', function () {
      $('.js-overlay').show();
      $('.js-modal').show();
    });
    const closeModal = () => {
      $('.js-overlay').hide();
      $('.js-modal').hide();
    };
    $('.js-modal-close').on('click', function () {
      closeModal();
    });
    $('.js-overlay').on('click', function () {
      closeModal();
    });
    $('.js-modal-submit').on('click', function () {
      closeModal();
    });
  });
  $('#settings-user-registration-email').each(function () {
    const emailElem = $('input[name="consumer_user_proxy[email]"]');
    const submitButton = $('.form-submit input[type="submit"]');
    const validateForm = () => {
      return emailElem.val().length > 0;
    };
    const toggleSubmit = () => {
      submitButton.prop('disabled', !validateForm());
    }
    emailElem.on('input', function () {
      toggleSubmit();
    });
    toggleSubmit();
  });
  $('#settings-user-registration-password').each(function () {
    const currentPasswordElem = $('input[name="consumer_user_proxy[current_password]"]');
    const passwordElem = $('input[name="consumer_user_proxy[password]"]');
    const passwordConfirmationElem = $('input[name="consumer_user_proxy[password_confirmation]"]');
    const submitButton = $('.form-submit input[type="submit"]');
    const validateForm = () => {
      return currentPasswordElem.val().length > 0 && passwordElem.val().length > 0 && passwordConfirmationElem.val().length > 0;
    };
    const toggleSubmit = () => {
      submitButton.prop('disabled', !validateForm());
    }
    currentPasswordElem.on('change', function () {
      toggleSubmit();
    });
    passwordElem.on('input', function () {
      toggleSubmit();
    });
    passwordConfirmationElem.on('input', function () {
      toggleSubmit();
    });
    toggleSubmit();
  });
});
