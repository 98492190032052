import Turbolinks from 'turbolinks';
import { isSafari, setupTwitter, connectTwitter, disconnectTwitter } from '../shared/twitter';

document.addEventListener("turbolinks:load", function () {
  if (isSafari()) {
    $('.js-twitter-login,.js-twitter-connect').each(() => {
      setupTwitter();
    });
  }
  $('.js-twitter-connect').on('click', () => {
    if ($('.js-twitter-connect').parent().hasClass('linked')) {
      disconnectTwitter();
    } else {
      connectTwitter((row) => {
        const data = {
          twitter_account: {
            access_token: row.access_token,
            access_token_secret: row.access_token_secret,
            user_id: row.user_id,
            screen_name: row.screen_name
          }
        };
        $.ajax({
          url: '/consumer/social_accounts/twitter_accounts/registration',
          method: 'post',
          data: data,
          success: () => {
            $('.js-twitter-connect').parent().toggleClass('linked');
          },
          error: (e) => {
            alert(e.responseJSON.oauth_provider.join('/'));
          }
        });
      });
    }
  });
  $('.js-twitter-login').on('click', () => {
    const returnTo = $('input[name="return_to"]').val();
    connectTwitter((row) => {
      const data = {
        twitter_account: {
          access_token: row.access_token,
          access_token_secret: row.access_token_secret,
          user_id: row.user_id,
          screen_name: row.screen_name
        }
      };
      $.ajax({
        url: '/consumer/social_accounts/twitter_accounts/session',
        method: 'post',
        data: data,
        success: () => {
          Turbolinks.visit(returnTo || '/');
        },
        error: (e) => {
          storeSocialAccountData(data);
          const query = returnTo ? '?return_to=' + returnTo : '';
          Turbolinks.visit('/users/sign_up' + query);
        }
      });
    });
  });
});
