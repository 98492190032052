import Turbolinks from 'turbolinks';

document.addEventListener("turbolinks:load", function () {
  const connectLine = (callback) => {
    const redirectUri = location.protocol + "//" + location.host + '/users/session/line/new';
    const listener = async (event) => {
      if (event.data && event.data.type !== "line_oauth") {
        return;
      }
      window.removeEventListener("message", listener);

      $.ajax({
        url: '/consumer/social_accounts/line_accounts/access_token',
        method: 'post',
        data: {
          code: event.data.params["code"],
          redirect_uri: redirectUri
        },
        success: (accessToken) => {
          callback({
            access_token: accessToken.access_token,
            user_id: accessToken.decoded_id_token[0].sub,
            screen_name: accessToken.decoded_id_token[0].name
          });
        }
      });
    };
    window.addEventListener("message", listener, false);

    window.open(
      `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${window.applicationConfig.lineChannelId}&redirect_uri=${redirectUri}&state=state&scope=openid%20profile`,
      undefined,
      `width=500,height=600,toolbar=no,menubar=no,left=${
        window.innerWidth / 2 - 250
      },top=${window.innerHeight / 2 - 300}`
    );
  };
  const disconnectLine = () => {
    $.ajax({
      url: '/consumer/social_accounts/line_accounts/registration',
      method: 'delete',
      success: () => {
        $('.js-line-connect').parent().toggleClass('linked');
      },
      error: (e) => {
        alert(e.responseJSON.oauth_provider.join('/'));
      }
    });
  };

  $('.js-line-connect').on('click', () => {
    if ($('.js-line-connect').parent().hasClass('linked')) {
      disconnectLine();
    } else {
      connectLine((row) => {
        const data = {
          line_account: {
            access_token: row.access_token,
            user_id: row.user_id,
            screen_name: row.screen_name
          }
        };
        $.ajax({
          url: '/consumer/social_accounts/line_accounts/registration',
          method: 'post',
          data: data,
          success: () => {
            $('.js-line-connect').parent().toggleClass('linked');
          },
          error: (e) => {
            alert(e.responseJSON.oauth_provider.join('/'));
          }
        });
      });
    }
  });
  $('.js-line-login').on('click', () => {
    const returnTo = $('input[name="return_to"]').val();
    connectLine((row) => {
      const data = {
        line_account: {
          access_token: row.access_token,
          user_id: row.user_id,
          screen_name: row.screen_name
        }
      };
      $.ajax({
        url: '/consumer/social_accounts/line_accounts/session',
        method: 'post',
        data: data,
        success: () => {
          Turbolinks.visit(returnTo || '/');
        },
        error: (e) => {
          storeSocialAccountData(data);
          const query = returnTo ? '?return_to=' + returnTo : '';
          Turbolinks.visit('/users/sign_up' + query);
        }
      });
    });
  });
});
