import Turbolinks from 'turbolinks';

document.addEventListener("turbolinks:load", function () {
  setupSlick($('.js-recommendation').not('.slick-initialized'));
  toggleSlickByMediaQuery(mediaQuery, false);
  $('#home-page').each(function() {
    rotateGenreForPopularityRanking();
    setupGenrePopularityRankingTabs();
  });

  var position = 0;
  var button = $('.js-home-free-ticket');
  $(window).on('scroll', function () {
    var d = $(this).scrollTop() - position;
    if (d * d >= 2) {
      if ($(this).scrollTop() > position) {
        button.addClass('hide');
      } else {
        button.removeClass('hide');
      }
    }
    position = $(this).scrollTop();
  });
});

document.addEventListener("turbolinks:before-cache", function () {
  $('.js-recommendation.slick-initialized').slick('unslick');
  $('.js-slide-stories.slick-initialized').slick('unslick');
});

document.addEventListener("turbolinks:render", function () {
  setupSlick($('.js-recommendation'));
  setupSlideStories($('.js-slide-stories'));
});

function setupSlick(elem) {
  elem.slick({
    autoplay: true,
    infinite: true,
    speed: 300,
    variableWidth: true,
    arrows: false,
    dots: true,
    swipeToSlide: true,
    slidesToShow: 3,
    centerMode: true,
    responsive: [{
      breakpoint: 1120,
      settings: {
        dots: false
      }
    }]
  });
}

const popularityRankingGenreListIndexKey = 'popularityRankingGenreListIndex';

function rotateGenreForPopularityRanking() {
  let val = localStorage.getItem(popularityRankingGenreListIndexKey);
  const tabs = $('.contents-box.home-ranking li.home-ranking-tab');
  if (val == null) {
    val = 0;
    localStorage.setItem(popularityRankingGenreListIndexKey, val);
  } else {
    val = Number(val) + 1;
    if (val >= tabs.length) {
      val = 0;
    }
    localStorage.setItem(popularityRankingGenreListIndexKey, val);
  }
  tabs.filter('.select').removeClass('select');
  const selectTab = tabs.filter(`:nth-child(${val + 1})`).addClass('select')[0];
  if (selectTab) {
    const offsetLeft = selectTab.offsetLeft;
    $('.genres.genres-slider.home-ranking-genres').scrollLeft(offsetLeft);

    const index = tabs.index(selectTab);
    $('.home-ranking-content').removeClass('show').eq(index).addClass('show');
  }
}

function setupGenrePopularityRankingTabContent(elem) {
  const genreId = elem.dataset.genre_id;
  if (!genreId) {
    return;
  }
  const rankingContent = document.querySelector(`.home-ranking-content.genre-${genreId}`);
  if (rankingContent.innerHTML.length > 0) {
    return;
  }
  $.ajax({
    url: `/weekly_popularity_ranking?genre_id=${genreId}`,
    dataType: "script"
  });
}

function setupGenrePopularityRankingTabs() {
  document.querySelectorAll('.home-ranking-tab').forEach(function(elem) {
    elem.addEventListener('click', function() {
      setupGenrePopularityRankingTabContent(this);
    });
  });
  var loaded = false;
  const callback = (entries) => {
    if (entries[0] && entries[0].intersectionRatio == 0 && !loaded) {
      var loaded = true;
      return;
    }
    var loaded = true;
    const selectedElem = document.querySelector('.home-ranking-tab.select');
    if (selectedElem)  {
      setupGenrePopularityRankingTabContent(selectedElem);
    }
  };
  const options = {
    rootMargin: "30px",
    threshold: 0.1
  };
  const observer = new IntersectionObserver(callback, options);
  observer.observe(document.querySelector('.contents-box.home-ranking'))
}

const mediaQuery = window.matchMedia('(min-width: 1100px)');


function setupSlideStories(elem) {
  elem.slick({
    autoplay: false,
    infinite: false,
    speed: 300,
    variableWidth: true,
    arrows: true,
    prevArrow: '<div class="slick-prev-wrapper"><button type=”button” class=”slick-prev”>Previous</button></div>',
    nextArrow: '<div class="slick-next-wrapper"><button type=”button” class=”slick-next”>Next</button></div>',
    dots: false,
    swipeToSlide: true,
    slidesToShow: 4,
    responsive: [{
      breakpoint: 1100,
      settings: {
        arrows: false,
        slidesToShow: 2
      }
    }]
  });
}

mediaQuery.addListener(toggleSlickByMediaQuery);

function toggleSlickByMediaQuery(mm, layoutChanged = true) {
  if (mm.matches) {
    setupSlideStories($('.js-slide-stories').not('.slick-initialized'));
  } else {
    const callback = function() {
      $('.js-slide-stories.slick-initialized').slick('unslick');
    };
    if (layoutChanged) {
      setTimeout(callback, 500);
    } else {
      callback();
    }
  }
  $('.js-slide-stories').css('overflow', '');
}
