import Turbolinks from 'turbolinks';

document.addEventListener("turbolinks:load", function () {
  const connectFacebook = (callback) => {
    const loadScript = (src, success, fail) => {
      const head = document.head || document.getElementsByTagName("head")[0];
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.src = src;
      script.onload = function () {
        this.onerror = this.onload = null;
        success();
      };
      script.onerror = function () {
        this.onerror = this.onload = null;
        fail();
      };
      head.appendChild(script);
    };
    window.fbAsyncInit = function() {
      FB.init({
        appId : window.applicationConfig.facebookId,
        autoLogAppEvents : true,
        xfbml : true,
        version : 'v10.0'
      });
    };
    loadScript(
      "https://connect.facebook.net/en_US/sdk.js",
      () => {
        window.FB.login(function (response) {
          if (response.authResponse) {
            window.FB.api(
              "/me",
              "GET",
              { fields: "id,name,email,picture.type(large)" },
              function (response) {
                const { id, name, email } = response;
                window.FB.getLoginStatus(function (response) {
                  callback({
                    user_id: id,
                    name: name,
                    email: email,
                    access_token: response.authResponse.accessToken,
                    status: response.status
                });
                });
              }
            );
          } else {
            console.log("User cancelled login or did not fully authorize.");
          }
        });
      },
      () => {}
    );
  };
  const disconnectFacebook = () => {
    $.ajax({
      url: '/consumer/social_accounts/facebook_accounts/registration',
      method: 'delete',
      success: () => {
        $('.js-facebook-connect').parent().toggleClass('linked');
      },
      error: (e) => {
        alert(e.responseJSON.oauth_provider.join('/'));
      }
    });
  };
  $('.js-facebook-connect').on('click', () => {
    if ($('.js-facebook-connect').parent().hasClass('linked')) {
      disconnectFacebook();
    } else {
      connectFacebook((row) => {
        const {user_id, name, email, access_token, status} = row;
        if (status === 'connected') {
          const data = {
            facebook_account: {
              access_token: access_token,
              email: email,
              user_id: user_id,
              provider: 'facebook',
              screen_name: name,
            }
          };
          $.ajax({
            url: '/consumer/social_accounts/facebook_accounts/registration',
            method: 'post',
            data: data,
            success: () => {
              $('.js-facebook-connect').parent().toggleClass('linked');
            },
            error: (e) => {
              alert(e.responseJSON.oauth_provider.join('/'));
            }
          });
        }
      });
    }
  });
  $('.js-facebook-login').on('click', () => {
    const returnTo = $('input[name="return_to"]').val();
    connectFacebook((row) => {
      const {user_id, name, email, access_token, status} = row;
      if (status === 'connected') {
        const data = {
          facebook_account: {
            access_token: access_token,
            email: email,
            user_id: user_id,
            provider: 'facebook',
            screen_name: name,
          }
        };
        $.ajax({
          url: '/consumer/social_accounts/facebook_accounts/session',
          method: 'post',
          data: data,
          success: () => {
            Turbolinks.visit(returnTo || '/');
          },
          error: (e) => {
            storeSocialAccountData(data);
            const query = returnTo ? '?return_to=' + returnTo : '';
            Turbolinks.visit('/users/sign_up' + query);
          }
        });
      }
    });
  });
});

