document.addEventListener("turbolinks:load", function () {
  $('#episode-comments-page').each(() => {
    setupSlideStories($('.content-media-carousel .media-carousel-list').not('.slick-initialized'));

    $(document.body).on('click', '.js-episode-comment-menu', function () {
      $(this).parents('.episode-comment-item').find('.episode-comment-menu').toggle();
    });
    $(document.body).on('click', '.js-modal-reaction-update-close,.js-modal-reaction-update-overlay', function () {
      $('.js-modal-reaction-update-overlay').hide();
      $('.js-modal-reaction-update-dialog').hide();
    });

    $(document.body).on('click', '.js-modal-episode-comments-close,.js-modal-episode-comments-overlay', function () {
      $('.js-modal-episode-comments-overlay').hide();
      $('.js-modal-episode-comments-dialog').hide();
    });
    $(document.body).on('click', '.js-new-episode-comment-button', function () {
      $('.js-modal-episode-comments-overlay').hide();
      $('.js-modal-episode-comments-dialog').hide();
    });
  });
});

function setupSlideStories(elem) {
  if (navigator.userAgent.match(/mobile/i)) {
    return;
  }
  elem.slick({
    autoplay: false,
    infinite: false,
    speed: 300,
    variableWidth: true,
    arrows: false,
    dots: false,
    swipeToSlide: true,
    slidesToShow: 2
  });
  elem.find('.slick-list').css({overflow: 'hidden'});
}
