import Turbolinks from 'turbolinks';

document.addEventListener("turbolinks:load", function () {
  $('.js-stories-sort').on('click', function () {
    Turbolinks.visit($(this).data('url'));
  });

  const scrollToSelectedGenre = () => {
    const offset = $('.genres.search-genres li .select').offset();
    if (offset) {
      $('.genres.search-genres').scrollLeft(offset.left);
    }
  };

  $(window).on('resize', scrollToSelectedGenre);
  scrollToSelectedGenre();
})
