let storedDocumentScrollTop = null;
let storedGenresScrollLeft = null;

document.addEventListener('turbolinks:load', function () {
  const selectedElem = $('.genres.contest-stories-genres li.select')[0];
  if (selectedElem) {
    if (!storedGenresScrollLeft) {
      $('.genres.contest-stories-genres').scrollLeft(selectedElem.offsetLeft);
    }
    storedGenresScrollLeft = null;
  }
  let tabs = $('.home-ranking-tab');
  tabs.on('click', function(event) {
    if ($(event.target).hasClass('home-ranking-tab')) {
      return;
    }
    if ($(event.target).parent().parent().data('move')) {
      return;
    }
    tabs.filter('.select').removeClass('select');
    $(this).addClass('select');
    const index = tabs.index(this);
    if ($('.home-ranking-content').length > 0) {
      $('.home-ranking-content').removeClass('show').eq(index).addClass('show');
    } else {
      Turbolinks.visit($(event.target).data('url'));
      storedDocumentScrollTop = $(document).scrollTop();
      storedGenresScrollLeft = $('.genres.contest-stories-genres').scrollLeft();
    }
  });
  $('#contest-page .js-stories-sort').on('click', function () {
    storedDocumentScrollTop = $(document).scrollTop();
    storedGenresScrollLeft = $('.genres.contest-stories-genres').scrollLeft();
  });
})

document.addEventListener('turbolinks:render', function () {
  if (storedDocumentScrollTop) {
    $(document).scrollTop(storedDocumentScrollTop);
    storedDocumentScrollTop = null;
  }
  if (storedGenresScrollLeft) {
    $('.genres.contest-stories-genres').scrollLeft(storedGenresScrollLeft);
  }
});
