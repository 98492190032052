let currentRequestToken = null;
const redirectUri = location.protocol + "//" + location.host + '/users/session/twitter/new';

export const isSafari = () => {
  var userAgent = window.navigator.userAgent.toLowerCase();
  if (userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1) {
    return false;
  } else if(userAgent.indexOf('edge') != -1) {
    return false;
  } else if(userAgent.indexOf('chrome') != -1) {
    return false;
  } else if(userAgent.indexOf('safari') != -1) {
    return true;
  } else if(userAgent.indexOf('firefox') != -1) {
    return false;
  } else if(userAgent.indexOf('opera') != -1) {
    return false;
  } else {
    return false;
  }
}

export const setupTwitter = () => {
  $.ajax({
    url: '/consumer/social_accounts/twitter_accounts/request_token',
    method: 'post',
    data: {
      redirect_uri: redirectUri
    },
    async: false,
    success: (requestToken) => {
      currentRequestToken = requestToken;
    }
  });
};

export const connectTwitter = (callback) => {
  if (!isSafari()) {
    setupTwitter();
  }
  const listener = async (event) => {
    if (event.data && event.data.type !== "twitter_oauth") {
      return;
    }
    window.removeEventListener("message", listener);

    $.ajax({
      url: '/consumer/social_accounts/twitter_accounts/access_token',
      method: 'post',
      data: {
        redirect_uri: redirectUri,
        token: event.data.params["oauth_token"],
        verifier: event.data.params["oauth_verifier"],
      },
      success: (accessToken) => {
        callback({
          access_token: accessToken.oauth_token,
          access_token_secret: accessToken.oauth_token_secret,
          user_id: accessToken.user_id,
          screen_name: accessToken.screen_name
        });
      }
    });
  };
  window.addEventListener("message", listener, false);

  window.open(
    `https://api.twitter.com/oauth/authenticate?oauth_token=${currentRequestToken.token}`,
    undefined,
    `width=500,height=600,toolbar=no,menubar=no,left=${
      window.innerWidth / 2 - 250
    },top=${window.innerHeight / 2 - 300}`
  );
};

export const disconnectTwitter = () => {
  $.ajax({
    url: '/consumer/social_accounts/twitter_accounts/registration',
    method: 'delete',
    success: () => {
      $('.js-twitter-connect').parent().toggleClass('linked');
    },
    error: (e) => {
      alert(e.responseJSON.oauth_provider.join('/'));
    }
  });
};
